import React, { useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import LOGO from "assets/logos/MORENA_FM.png";
import anime from "animejs";

export default function Radio(props) {
  useEffect(() => {
    anime({
      targets: ["img"],
      scale: [1.1, 1],
      duration: 2000,
      easing: `easeInOutQuad`
    });

    anime({
      targets: ["img", "h1"],
      opacity: [0, 1],
      delay: (el, i) => i * 1000,
      easing: `easeInOutQuad`,
      duration: 2000
    });
  }, []);

  const estilo = {
    background: "red",
    height: `30vh`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    fontFamily: `Gotham Title`,
    color: `#fff`,
    textShadow: `0 2px 2px #0005`,
    background: `linear-gradient(#111 5%, #000 100%)`
  };

  return (
    <Container fluid>
      <Row style={{ background: `#111` }}>
        <Col
          style={{ height: `70vh` }}
          className="col-md-4 d-flex align-items-center offset-md-4"
        >
          <Image fluid src={LOGO} />
        </Col>
      </Row>
      <Row>
        <Col style={estilo}>
          <h1>Estamos em manutenção!</h1>
        </Col>
      </Row>
    </Container>
  );
}
