import React from "react";
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import config from "config.json";

export default withScriptjs(
  withGoogleMap(props => {
    return (
      <GoogleMap
        defaultZoom={17}
        defaultCenter={{ lat: -34.397, lng: 150.644 }}
        center={props.center}
      ></GoogleMap>
    );
  })
);
