import React from "react";
import { Legenda, Linha } from "./style";
import { Container, Row, Col } from "react-bootstrap";
import FA from "react-fontawesome";

export default function Legend(props) {
  const { state } = props;

  return (
    <>
      <Row>
        <Col>
          <Legenda>
            <Row>
              <Col
                className="p-2 bg-theme-primary text-white"
                style={{ borderTopLeftRadius: 2, borderTopRightRadius: 2 }}
              >
                <span>
                  {props.variant === "radio"
                    ? state.bloco.Radio
                    : state.bloco.Emissora}
                </span>
              </Col>
            </Row>
            <LegendItem
              icon="users"
              title="População:"
              value={
                props.variant === "radio"
                  ? state.bloco.Populacao_radio
                  : state.bloco.Populacao
              }
            />
            {props.variant !== "radio" && state.bloco.Municipios && (
              <LegendItem
                icon="city"
                title="Municípios:"
                value={state.bloco.Municipios}
              />
            )}
            {props.variant !== "radio" && state.bloco.Domicilios && (
              <LegendItem
                icon="tv"
                title="Domicílios c/ TV:"
                value={state.bloco.Domicilios}
              />
            )}
            {props.variant !== "radio" && state.bloco.TP && (
              <LegendItem
                icon="bullhorn"
                title="Telespectadores potenciais:"
                value={state.bloco.TP}
              />
            )}
            {props.variant === "radio" && state.bloco.Abran && (
              <LegendItem
                icon="broadcast-tower"
                title="Abrangência:"
                value={state.bloco.Abran}
              />
            )}
            {props.variant === "radio" && state.bloco.Freq && (
              <LegendItem
                icon="signal"
                title="Frequência:"
                value={state.bloco.Freq}
              />
            )}
          </Legenda>
        </Col>
      </Row>
      <Row className="mb-5" />;
    </>
  );
}

function LegendItem(props) {
  return (
    <Linha className="pt-1 pb-1">
      <Col className="col-3 p-0  d-flex align-items-center justify-content-center">
        <span className="myIcon">
          <FA name={props.icon} />
        </span>
      </Col>
      <Col className="col-9 p-0 text-left">
        <Row>
          <Col className="col-12 myTitle">{props.title}</Col>
        </Row>
        <Row>
          <Col className="col-12 myContent">{props.value}</Col>
        </Row>
      </Col>
    </Linha>
  );
}
