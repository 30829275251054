import styled from "styled-components";
import config from "config.json";

// const pattern = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAEElEQVQImWNgYGD4z0AZAABABAEAuUSdjgAAAABJRU5ErkJggg==`;

export const Menu = styled.div`
  flex: 1;
  padding: 0.75cm 1cm;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & #brand {
    color: #fff;
    font-size: 2em;
    cursor: pointer;
  }

  & #menu_items {
    list-style: none;
    display: flex;

    & li {
      padding: 10px;
      // background: rgba(255,255,255,0.05);
      // box-shadow: 0px 2px 2px rgba(0,0,0,0.10);
      color: #fff;
      top: 0;
      transition: top 0.25s ease-in-out;
      cursor: pointer;
      font-family: "Futura";
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.8em;
      letter-spacing: 3px;

      &::selection {
        display: none;
      }

      &:not(:last-child) {
        margin-right: 30px;
      }

      &:hover {
        // background: rgba(255,255,255,0.1);
      }

      &.hire {
        background: ${config.theme.primary};
        color: ${config.theme.primary_text};
      }
    }
  }

  & #cookie-banner-lgpd{position:fixed;z-index:2147483647;bottom:16px;left:16px;right:16px;margin:auto;max-width:1334px;min-height:70px;box-shadow:0 2px 4px 0 rgba(0,0,0,0.4);border:solid 1px #eeeeee;background-color:#fff}
  & #cookie-banner-lgpd.cookie-banner-lgpd-animated{animation-duration:1s;animation-fill-mode:both;animation-iteration-count:1}
  & #cookie-banner-lgpd.cookie-banner-lgpd-animated.cookie-banner-lgpd-visible{animation-name:bounceInUp}
  & #cookie-banner-lgpd.cookie-banner-lgpd-animated.cookie-banner-lgpd-hidden{animation-name:bounceOutDown}
  & #cookie-banner-lgpd .cookie-banner-lgpd-container{display:flex;align-items:center;justify-content:space-between;width:100%;min-height:inherit}
  & #cookie-banner-lgpd .cookie-banner-lgpd-container .cookie-banner-lgpd_text-box{vertical-align:middle;padding:16px}
  & #cookie-banner-lgpd .cookie-banner-lgpd-container .cookie-banner-lgpd_text-box .cookie-banner-lgpd_text{font-family:opensans, helvetica, arial, sans-serif;font-size:12px;font-weight:normal;font-stretch:normal;font-style:normal;line-height:normal;letter-spacing:-0.45px;color:#333}
  & #cookie-banner-lgpd .cookie-banner-lgpd-container .cookie-banner-lgpd_text-box .cookie-banner-lgpd-link{color:#0069de;text-decoration:underline;cursor:pointer}
  & #cookie-banner-lgpd .cookie-banner-lgpd-container .cookie-banner-lgpd_button-box{padding:16px 16px 16px 0}
  & #cookie-banner-lgpd .cookie-banner-lgpd-container .cookie-banner-lgpd_button-box .cookie-banner-lgpd_accept-button{padding:8px 16px;border-radius:3px;font-family:opensans, helvetica, arial, sans-serif;font-size:14px;font-weight:bold;font-stretch:normal;font-style:normal;line-height:1;letter-spacing:normal;text-align:center;color:#fff;border:0;cursor:pointer}
  & #cookie-banner-lgpd .cookie-banner-lgpd-container .cookie-banner-lgpd_button-box .cookie-banner-lgpd_accept-button:focus{outline:none}
  @media only screen and (max-width: 800px){
    #cookie-banner-lgpd .cookie-banner-lgpd-container{flex-flow:column}
    #cookie-banner-lgpd .cookie-banner-lgpd-container .cookie-banner-lgpd_text-box{padding:16px 16px 0px 16px}
    #cookie-banner-lgpd .cookie-banner-lgpd-container .cookie-banner-lgpd_button-box{width:100%;padding:16px;box-sizing:border-box}
    #cookie-banner-lgpd .cookie-banner-lgpd-container .cookie-banner-lgpd_button-box .cookie-banner-lgpd_accept-button{width:100%}
  }
  & #cookie-banner-lgpd a, #cookie-banner-lgpd a:hover{color:#0069de !important; font-weight:bold;}
`;
