import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import config from "../../config.json";
import anime from "animejs";

import { useWindowSize } from "react-use";
import { useEffect } from "react";

import bg from "assets/img/bg.jpg";
import bg2 from "assets/img/bg2.jpg";

const pattern = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAEElEQVQImWNgYGD4z0AZAABABAEAuUSdjgAAAABJRU5ErkJggg==`;

const Background_s = styled.div`
  background: ${props => (props.image ? `url(${props.image})` : "#222")};
  background-size: cover;
  background-position: center 45%;
  color: #fff;
  height: 100vh;
  width: 100%;
  padding: 20px;
  position: absolute;

  &::before {
    background: url(${pattern}) repeat, rgba(0, 0, 0, 0.45);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: 0;
    transition: transform 1s ease-in-out;
  }
`;

export default function(props) {
  const animation = useRef(null);
  const [animating, turnAnimation] = useState(false);

  const enterZoom = () => {
    if (animating) {
      animation.current.pause();
    }
    turnAnimation(true);
    animation.current = anime({
      targets: ".zoomedBackground",
      scale: { value: 1.075, duration: 3000 },
      elasticity: 0,
      easing: `easeInOutQuad`,
      complete: () => {
        turnAnimation(false);
      }
    });
  };

  const exitZoom = () => {
    if (animating) {
      animation.current.pause();
    }
    turnAnimation(true);
    animation.current = anime({
      targets: ".zoomedBackground",
      scale: 1,
      duration: 1500,
      elasticity: 0,
      easing: `easeInOutQuad`,
      complete: () => {
        turnAnimation(false);
      }
    });
  };

  //Background mudando
  const projeto = useSelector(state => state.project);

  const handleBg = () => {
    let retorno;
    let retorno2;
    switch (projeto.bg) {
      case "1":
        retorno = bg;
        break;
      case "2":
        retorno = bg2;
        break;
      default:
        retorno = bg;
        break;
    }

    try {
      retorno2 = localStorage.getItem("background");
      switch (retorno2) {
        case "1":
          retorno = bg;
          break;
        case "2":
          retorno = bg2;
          break;
      }
    } catch (e) {}

      return retorno;
    };

  return (
    <div
      onMouseEnter={enterZoom}
      onMouseLeave={exitZoom}
      style={{ overflow: "hidden", width: `100%`, height: `100vh` }}
    >
      <Background_s className={`zoomedBackground`} image={handleBg()} />
      <div>{props.children}</div>
    </div>
  );
}
