import React from "react";
import { Wrapper, Content } from "./style";

import config from "config.json";
import anime from "animejs";

import bg from "assets/img/bg.jpg";

import LOGO from "assets/img/logo.png";
import CENTRO_AMERICA from "assets/logos/CENTRO_AMERICA.png";
import CENTRO_AMERICA_FM from "assets/logos/CENTRO_AMERICA_FM.png";
import G1 from "assets/logos/G1.png";
import MORENA from "assets/logos/MORENA.png";

//Components
import Background from "components/Background";

//3rd party
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import Menu from "components/Menu";
import { useState } from "react";
import { useEffect } from "react";
import _ from "lodash";

import dados from "pages/Cobertura/dados.json";

export default function (props) {
  const [localState, setLocalState] = useState({ isOpen: false });

  useEffect(() => {
    const tgt = document.getElementsByClassName("_menu_ref")[0];

    if (!tgt) {
      return;
    }

    const toggleMenu = () => {
      // //console.log("clicou");
      setLocalState({ ...localState, isOpen: !localState.isOpen });
    };

    tgt.addEventListener("click", toggleMenu);

    return () => {
      tgt.removeEventListener("click", toggleMenu);
    };
  }, [localState.isOpen]);

  useEffect(() => {
    let tl = anime
      .timeline({ autoplay: true, easing: `easeInOutQuad` })
      .add({
        targets: ["h1", "p"],
        opacity: [0, 1],
        translateY: (el, i) => [20 * (i + 1), 0]
      })
      .add({ targets: [".animado"], width: [0, 250] }, 400)
      .add(
        {
          targets: [".background-dark-transparent"],
          scale: [1.25, 1],
          delay: (el, i) => 250 * i,
          opacity: [0, 1]
        },
        800
      );
  }, []);

  return (
    <>
      <Background image={bg}>
        <Wrapper>
          <Container fluid>
            <Row
              style={{
                top: 0,
                position: `fixed`,
                width: `100%`,
                zIndex: !!localState.isOpen ? 99999 : 10
              }}
            >
              <Menu></Menu>
            </Row>
            <div id="conteudo">
              <Container>
                <Row className="text-right">
                  <Col>
                    <Content.h1>COMERCIAL</Content.h1>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col style={{ position: `relative` }}>
                    <Content.FloatingBar />
                    <Row className="p-5 mb-4 background-dark-transparent text-right">
                      <Col>
                        <Content.h3 className="mb-4">
                          TV CENTRO AMÉRICA | MT
                        </Content.h3>
                        {_.chunk(dados[0].blocos, 3).map(chunk => {
                          return (
                            <Row className="p-2 text-center">
                              {chunk.map((cidade, cidadeIndex) => {
                                return (
                                  <Col className={`col-4`}>
                                    <Content.cidadeTitle>
                                      {cidade.TVCA}
                                    </Content.cidadeTitle>
                                    <Content.cidadeTelefone>
                                      {cidade.TelefoneComercial ||
                                        cidade.Telefone}
                                    </Content.cidadeTelefone>
                                  </Col>
                                );
                              })}
                            </Row>
                          );
                        })}                        
                      </Col>
                    </Row>

                    <Row className="p-5 mb-4 background-dark-transparent text-right">
                      <Col>
                        <Content.h3 className="mb-4">
                          CENTRO AMÉRICA FM | MT
                        </Content.h3>
                        {_.chunk(dados[2].blocos, 3).map(chunk => {
                          return (
                            <Row className="p-2 text-center">
                              {chunk.map((cidade, cidadeIndex) => {
                                return (
                                  <Col className={`col-4`}>
                                    <Content.cidadeTitle>
                                      {cidade.TVCA}
                                    </Content.cidadeTitle>
                                    <Content.cidadeTelefone>
                                      {cidade.TelefoneComercial ||
                                        cidade.Telefone}
                                    </Content.cidadeTelefone>
                                  </Col>
                                );
                              })}
                            </Row>
                          );
                        })}                        
                      </Col>
                    </Row>


                    <Row className="p-5 mb-4 background-dark-transparent text-right">
                      <Col>
                        <Content.h3 className="mb-4">TV MORENA | MS</Content.h3>
                        {_.chunk(dados[1].blocos, 3).map(chunk => {
                          return (
                            <Row className="p-2 text-center">
                              {chunk.map((cidade, cidadeIndex) => {
                                return (
                                  <Col className={` col-4`}>
                                    <Content.cidadeTitle>
                                      {cidade.TVCA}
                                    </Content.cidadeTitle>
                                    <Content.cidadeTelefone>
                                      {cidade.TelefoneComercial ||
                                        cidade.Telefone}
                                    </Content.cidadeTelefone>
                                  </Col>
                                );
                              })}
                            </Row>
                          );
                        })}
                      </Col>
                    </Row>
                    
                    <Row className="p-5 background-dark-transparent text-right">
                      <Col>
                        <Content.h3 className="mb-4">MORENA FM | MS</Content.h3>
                        {_.chunk(dados[3].blocos, 3).map(chunk => {
                          return (
                            <Row className="p-2 text-center">
                              {chunk.map((cidade, cidadeIndex) => {
                                return (
                                  <Col className={` col-4`}>
                                    <Content.cidadeTitle>
                                      {cidade.TVCA}
                                    </Content.cidadeTitle>
                                    <Content.cidadeTelefone>
                                      {cidade.TelefoneComercial ||
                                        cidade.Telefone}
                                    </Content.cidadeTelefone>
                                  </Col>
                                );
                              })}
                            </Row>
                          );
                        })}
                      </Col>
                    </Row>

                    <Content.FloatingBar end="true" />
                  </Col>
                </Row>
                <Row className="mb-5 mb-md-0">
                  <Col>&nbsp;</Col>
                </Row>
              </Container>
            </div>
          </Container>
        </Wrapper>
      </Background>
    </>
  );
}

function Moldura(props) {
  if (props.mobile) {
    return (
      <Col className="col-6">
        <Content.Bloco mobile>
          <img src={props.image} />
        </Content.Bloco>
      </Col>
    );
  }

  return (
    <Col className="col-4">
      <Content.Bloco>
        <img src={props.image} />
      </Content.Bloco>
    </Col>
  );
}
