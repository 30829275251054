import React, { useState, useEffect } from "react";
import { Wrapper, Conteudo } from "./style";

// Components
import Menu from "components/Menu";

export default function Error(props) {
  const [state, setState] = useState({
    code: "Carregando...",
    description: "Carregando..."
  });
  const { code } = props;

  const setError = (o = {}) => {
    setState({ ...state, code: o.code, description: o.description });
  };

  useEffect(() => {
    setError({ code, description: "Página não encontrada!" });
    document.title = `Erro ${state.code} - ${state.description}`;
  }, [code, state.code, state.description]);

  return (
    <Wrapper>
      <Menu />
      <Conteudo>
        <h1>Opa, houve um erro!</h1>
        <p>
          Erro {state.code} - "{state.description}"
        </p>
      </Conteudo>
    </Wrapper>
  );
}
