import styled from "styled-components";
import { Row } from "react-bootstrap";

export const Legenda = styled.div`
  border-radius: 3px;
  color: #222;
  font-family: "Gotham Title";
  font-size: 12pt;

  &:first-child {
    margin-right: 5px;
  }

  & .bg-theme-primary {
    background: #1596;
  }
`;

export const Linha = styled(Row)`
  background: #eee;

  &:nth-child(odd) {
    background: #fffc;
  }

  font-size: 0.8em;

  &:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  & .col.myTitle {
    text-shadow: none;
    color: #656565;
    text-transform: uppercase;
    font-size: 0.8em;
    margin: 0;
    margin-bottom: -5px;
  }

  & .col.myContent {
    font-size: 1.5em;
    text-shadow: none;
  }

  & span.myIcon {
    text-shadow: none;
    font-size: 1.2em;
    @media (min-width: 1024px) {
      font-size: 1.7em;
    }
    padding: 5px;
  }
`;
