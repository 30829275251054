import React from "react";
import FA from "react-fontawesome";
import { Wrapper, Content } from "./style";

import config from "config.json";
import coord from "./coords.json";
import informacoes from "./data.json";

import dados2 from "pages/Cobertura/dados.json";

// import logo from "assets/img/logo_white.png";
import bg from "assets/img/bg.jpg";

import GMaps from "components/googleMaps";

//Components
import Background from "components/Background";
import anime from "animejs";

//3rd party
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import Menu from "components/Menu";
import { useState } from "react";
import { useEffect } from "react";

export default function (props) {
  const [localState, setLocalState] = useState({ isOpen: false });

  useEffect(() => {
    const tgt = document.getElementsByClassName("_menu_ref")[0];

    if (!tgt) {
      return;
    }

    const toggleMenu = () => {
      // //console.log("clicou");
      setLocalState({ ...localState, isOpen: !localState.isOpen });
    };

    tgt.addEventListener("click", toggleMenu);

    return () => {
      tgt.removeEventListener("click", toggleMenu);
    };
  }, [localState.isOpen]);

  const [latlng, setLatlng] = useState(coord.cuiaba);

  const [city, setCity] = useState({
    name: localStorage.getItem("background") == "1" ? "cuiaba" : "campo_grande",
    data:
      informacoes[
      localStorage.getItem("background") == "1" ? "cuiaba" : "campo_grande"
      ]
  });

  const handleButton = () => {
    switch (city.name) {
      case "cuiaba":
        return "Campo Grande, MS";
      case "campo_grande":
        return "Cuiabá, MT";
    }
  };

  useEffect(() => {
    if (!coord[city.name]) {
      setLatlng(coord.cuiaba);
      return;
    }
    setLatlng(coord[city.name]);
  }, [city]);

  const toggleCity = () => {
    switch (city.name) {
      case "cuiaba":
        setCity({ name: "campo_grande", data: informacoes["campo_grande"] });
        break;
      case "campo_grande":
        setCity({ name: "cuiaba", data: informacoes["cuiaba"] });
        break;
    }
  };

  useEffect(() => {
    anime({
      targets: [".rowUp"],
      translateY: [30, 0],
      easing: `easeInOutQuad`,
      opacity: [0, 1],
      delay: (el, i) => i * 500
    });
  }, []);

  return (
    <>
      <Background image={bg}>
        <Wrapper>
          <Container fluid>
            <Row
              style={{
                top: 0,
                position: `fixed`,
                width: `100%`,
                zIndex: !!localState.isOpen ? 99999 : 10
              }}
            >
              <Menu></Menu>
            </Row>
            <div id="conteudo">
              <Row className="content">
                <Container fluid>
                  <Row className="text-right mb-3">
                    <Col>
                      <Content.mainTitle>FALE CONOSCO</Content.mainTitle>
                    </Col>
                  </Row>
                </Container>
                <Col xs={12}>
                  <Row className="form rowUp">
                    <Col xs={8} className="map col-12 col-md-8">
                      <GMaps
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${config.google.key}`}
                        center={latlng}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `100%` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                      ></GMaps>
                    </Col>
                    <Col xs={4} className="dados col-12 col-md-4">
                      <p className="title principal">{city.data.emissora}</p>
                      <p className="title">Endereço</p>
                      <p>{city.data.endereco}</p>
                      <p>CEP.: {city.data.CEP}</p>
                      <p className="title">Telefone</p>
                      <p>{city.data.Telefone}</p>
                      <p className="title">Email</p>
                      <p>{city.data.Email}</p>
                      <p>
                        <br />
                      </p>
                      <p>
                        <Button onClick={toggleCity}>
                          <FA name="change" /> Ver informações de{" "}
                          {handleButton()}
                        </Button>
                      </p>
                    </Col>
                  </Row>
                  <Row className="telefones rowUp text-center">
                    <Col className="col-12 col-sm-6">
                      <h3>Centro América</h3>
                      <h4>Televisão</h4>

                      {dados2[0].blocos.map(cidade => {
                        return (
                          <>
                            <p>
                              <b>{cidade.TVCA} |</b> {cidade.Telefone}
                            </p>
                            <p
                              style={{ transform: `translateY(-10px)` }}
                              dangerouslySetInnerHTML={{
                                __html: cidade.Endereco
                              }}
                            />
                          </>
                        );
                      })}

                      <h4>Rádio</h4>

                      {dados2[2].blocos.map(cidade => {
                        return (
                          <>
                            <p>
                              <b>{cidade.TVCA} |</b> {cidade.Telefone}
                            </p>
                            <p
                              style={{ transform: `translateY(-10px)` }}
                              dangerouslySetInnerHTML={{
                                __html: cidade.Endereco
                              }}
                            />
                          </>
                        );
                      })}
                    </Col>
                    <Col className="col-12 col-sm-6">
                      <h3>Morena</h3>
                      <h4>Televisão</h4>

                      {dados2[1].blocos.map(cidade => {
                        return (
                          <>
                            <p>
                              <b>{cidade.TVCA} |</b> {cidade.Telefone}
                            </p>
                            <p
                              style={{ transform: `translateY(-10px)` }}
                              dangerouslySetInnerHTML={{
                                __html: cidade.Endereco
                              }}
                            />
                          </>
                        );
                      })}

                      <h4>Rádio</h4>

                      {dados2[3].blocos.map(cidade => {
                        return (
                          <>
                            <p>
                              <b>{cidade.TVCA} |</b> {cidade.Telefone}
                            </p>
                            <p
                              style={{ transform: `translateY(-10px)` }}
                              dangerouslySetInnerHTML={{
                                __html: cidade.Endereco
                              }}
                            />
                          </>
                        );
                      })}

                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* <Row className="content"><Col>A</Col></Row> */}
            </div>
          </Container>
        </Wrapper>
      </Background>
    </>
  );
}
