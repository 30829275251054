import React from "react";
import { Wrapper, Content } from "./style";

import config from "config.json";
import anime from "animejs";

import bg from "assets/img/bg.jpg";

import LOGO from "assets/img/logo.png";
import CENTRO_AMERICA from "assets/logos/CENTRO_AMERICA.png";
import CENTRO_AMERICA_FM from "assets/logos/CENTRO_AMERICA_FM.png";
import G1 from "assets/logos/G1.png";
import MORENA from "assets/logos/MORENA.png";

//Components
import Background from "components/Background";

//3rd party
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import Menu from "components/Menu";
import { useState } from "react";
import { useEffect } from "react";

export default function (props) {
  const [localState, setLocalState] = useState({ isOpen: false });

  useEffect(() => {
    const tgt = document.getElementsByClassName("_menu_ref")[0];

    if (!tgt) {
      return;
    }

    const toggleMenu = () => {
      // //console.log("clicou");
      setLocalState({ ...localState, isOpen: !localState.isOpen });
    };

    tgt.addEventListener("click", toggleMenu);

    return () => {
      tgt.removeEventListener("click", toggleMenu);
    };
  }, [localState.isOpen]);

  useEffect(() => {
    let tl = anime
      .timeline({ autoplay: true, easing: `easeInOutQuad` })
      .add({
        targets: ["h1", "p"],
        opacity: [0, 1],
        translateY: (el, i) => [20 * (i + 1), 0]
      })
      .add({ targets: [".animado"], width: [0, 250] }, 400)
      .add({ targets: [".btn"], scale: [1.25, 1], opacity: [0, 1] }, 800);
  }, []);

  return (
    <>
      <Background image={bg}>
        <Wrapper>
          <Container fluid>
            <Row
              style={{
                top: 0,
                position: `fixed`,
                width: `100%`,
                zIndex: !!localState.isOpen ? 99999 : 10
              }}
            >
              <Menu></Menu>
            </Row>
            <div id="conteudo">
              <Container>
                <Row className="text-right">
                  <Col>
                    <Content.h1>
                      TRABALHE
                      <br />
                      CONOSCO
                    </Content.h1>
                    <Content.p className="mt-0 p-0 mt-md-4">
                      UM GRUPO GENUINAMENTE REGIONAL, APAIXONADO POR
                      COMUNICAÇÃO.
                    </Content.p>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col style={{ position: `relative` }}>
                    <Content.FloatingBar />
                    <Row className="p-5 text-center">
                      <Col>
                        <Button
                          onClick={() => {
                            window.open(
                              "https://rmc.onl/trabalheconosco/",
                              "_blank"
                            );
                          }}
                          className="p-4 btn-lg"
                        >
                          CLIQUE AQUI E DEIXE-NOS SEU CURRÍCULO
                        </Button>
                      </Col>
                    </Row>
                    <Content.FloatingBar end />
                  </Col>
                </Row>
                <Row className="mb-5 mb-md-0">
                  <Col>&nbsp;</Col>
                </Row>
              </Container>
            </div>
          </Container>
        </Wrapper>
      </Background>
    </>
  );
}

function Moldura(props) {
  if (props.mobile) {
    return (
      <Col className="col-6">
        <Content.Bloco mobile>
          <img src={props.image} />
        </Content.Bloco>
      </Col>
    );
  }

  return (
    <Col className="col-4">
      <Content.Bloco>
        <img src={props.image} />
      </Content.Bloco>
    </Col>
  );
}
