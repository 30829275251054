const default_state = {
  isLoading: true,
  bg: "1"
};

export default (state = default_state, action) => {
  switch (action.type) {
    case "PROJECT.SET_BACKGROUND":
      return { ...state, bg: action.bg };
    case "PROJECT.LOADING.OFF":
      // //console.log("Toggled off");
      return { ...state, isLoading: false };
    case "PROJECT.LOADING.ON":
      // //console.log("Toggled on");
      return { ...state, isLoading: true };
    default:
      return state;
  }
};
