import styled from "styled-components";

export const Wrapper = styled.div`
  background: #090909;
  position: absolute;
  bottom: 0;
  color: #fff;
  width: 100%;
  padding: 10px 20px;
  transition: padding-bottom 0.25s ease-in-out;
  font-family: "Futura";

  & .toggle {
    position: absolute;
    left: 0;
    right: 0;
    //    background: red;
    top: 0;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 100%;
    z-index: 20;
  }

  & span.rodape {
    font-size: 0.75em;
  }
`;
