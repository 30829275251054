import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config.json";
import { Background, Conteudo } from "./style";
import Rodape from "../../components/Rodape/Rodape";

import { Row, Col } from "react-bootstrap";

import Menu from "../../components/Menu";

// import logo from "assets/img/logo_white.png";
import bg from "assets/img/bg.jpg";
import bg2 from "assets/img/bg2.jpg";

import anime from "animejs";

import messages from "./messages.json";

function Home(props) {
  const projetoRedux = useSelector(state => state.project);

  useEffect(() => {
    document.title = "Grupo RMC";

    handleBackground();
  }, []);

  const [message, setMessage] = useState({
    title: "Missao",
    content: "a",
    loaded: false
  });

  const chooseMessage = () => {
    const selected = messages[Math.floor(Math.random() * messages.length)];
    setMessage({ ...message, ...selected, loaded: true });
  };

  useEffect(() => {
    chooseMessage();
  }, []);

  const dispatch = useDispatch();
  const handleBackground = () => {
    let bgAtual = localStorage.getItem("background");

    switch (bgAtual) {
      case "1":
        localStorage.setItem("background", 2);
        break;
      case "2":
        localStorage.setItem("background", 1);
        break;
      default:
        localStorage.setItem("background", 1);
        break;
    }

    bgAtual = localStorage.getItem("background");

    dispatch({ type: "PROJECT.SET_BACKGROUND", bg: bgAtual });
  };
  return (
    <>
      <Background>
        <div>
          <Menu></Menu>
          {message.loaded && (
            <Conteudo id="conteudo">
              <Row className="text-right p-3">
                <Col className="col-11 mt-5">
                  <h1 className="mainTitle">{message.title.toUpperCase()}</h1>
                  <p className="pt-2 pb-0">
                    {projetoRedux.bg === "1"
                      ? "TV Centro América | MT"
                      : "TV Morena | MS"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="description">
                    <Row className="text-right p-3 p-sm-5">
                      <Col className="col-11">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: message.description
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Conteudo>
          )}
        </div>
      </Background>
      <Rodape></Rodape>
    </>
  );
}

export default Home;
