import React from "react";
import { Wrapper, Content } from "./style";

import anime from "animejs";

import bg from "assets/img/bg.jpg";

import LOGO from "assets/img/logo.png";
import CENTRO_AMERICA from "assets/logos/CENTRO_AMERICA.png";
import CENTRO_AMERICA_FM from "assets/logos/CENTRO_AMERICA_FM.png";
import G1MT from "assets/logos/G1MT.png";
import G1MS from "assets/logos/G1MS.png";
import MORENA from "assets/logos/MORENA.png";
import MORENA_FM from "assets/logos/MORENA_FM.png";
import PRIMEIRA_PAGINA from "assets/logos/logoPP_230x99.png";

//Components
import Background from "components/Background";

//3rd party
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import Menu from "components/Menu";
import { useState } from "react";
import { useEffect } from "react";

export default function (props) {
  const [localState, setLocalState] = useState({ isOpen: false });

  useEffect(() => {
    const tgt = document.getElementsByClassName("_menu_ref")[0];

    if (!tgt) {
      return;
    }

    const toggleMenu = () => {
      // //console.log("clicou");
      setLocalState({ ...localState, isOpen: !localState.isOpen });
    };

    tgt.addEventListener("click", toggleMenu);

    return () => {
      tgt.removeEventListener("click", toggleMenu);
    };
  }, [localState.isOpen]);

  useEffect(() => {
    let tl = anime
      .timeline({ autoplay: true, easing: `easeInOutQuad` })
      .add({
        targets: ["h1", "p"],
        opacity: [0, 1],
        translateY: (el, i) => [20 * (i + 1), 0]
      })
      .add({ targets: [".animado"], width: [0, 250] }, 400)
      .add(
        {
          targets: [".bloco"],
          scale: [0, 1],
          delay: (el, i) => i * 220,
          opacity: 1
        },
        1000
      );
  }, []);

  return (
    <>
      <Background image={bg}>
        <Wrapper>
          <Container fluid>
            <Row
              style={{
                top: 0,
                position: `fixed`,
                width: `100%`,
                zIndex: !!localState.isOpen ? 99999 : 10
              }}
            >
              <Menu></Menu>
            </Row>
            <div id="conteudo">
              <Container>
                <Row className="text-right">
                  <Col>
                    <Content.h1>
                      NOSSAS
                      <br />
                      MARCAS
                    </Content.h1>
                    <Content.p className="mt-0 p-0 mt-md-4">
                      A REDE MATOGROSSENSE DE COMUNICAÇÃO É UM
                      <br /> DOS MAIORES GRUPOS DE MULTIMÍDIA DO PAÍS.
                    </Content.p>
                    <Content.p>
                      PRESENTE NAS PRINCIPAIS MÍDIAS DE COMUNICAÇÃO ATUAIS, SUAS
                      EMISSORAS DE TELEVISÃO, SITE E DE RÁDIO SÃO LÍDERES DE
                      MERCADO NOS ESTADOS DE MATO GROSSO E MATO GROSSO DO SUL,
                      PROPORCIONANDO AMPLO ALCANCE, RELEVÂNCIA E CREDIBILIDADE
                      AOS PARCEIROS.
                    </Content.p>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col style={{ position: `relative` }}>
                    <Content.FloatingBar />
                    <Row className="d-none d-md-flex mb-4">
                      <Moldura
                        link={"https://redeglobo.globo.com/tvcentroamerica/"}
                        image={CENTRO_AMERICA}
                      />
                      {/* <Moldura image={LOGO} /> */}
                      <Moldura
                        link={"http://primeirapagina.com.br/"}
                        zoom={0.7}
                        image={PRIMEIRA_PAGINA}
                      />
                      <Moldura
                        link={"http://www.cafm.com.br/"}
                        white
                        image={CENTRO_AMERICA_FM}
                      />
                    </Row>
                    <Row className="d-none d-md-flex">
                      <Moldura
                        link={"https://redeglobo.globo.com/tvmorena/"}
                        image={MORENA}
                      />
                      <Moldura
                        link={"https://g1.globo.com/mt/mato-grosso/"}
                        link2={"https://g1.globo.com/ms/mato-grosso-do-sul/"}
                        double
                        zoom={0.6}
                        image={G1MT}
                        image2={G1MS}
                      />
                      <Moldura
                        link={"http://www.morenafm.com.br/"}
                        white
                        image={MORENA_FM}
                      />
                    </Row>

                    
                    <Row className="d-flex mb-4 d-md-none">
                      <Moldura
                        link={"https://redeglobo.globo.com/tvmorena/"}
                        mobile
                        image={MORENA}
                      />
                      <Moldura
                        link={"https://redeglobo.globo.com/tvcentroamerica/"}
                        mobile
                        image={CENTRO_AMERICA}
                      />
                    </Row>

                    <Row className="d-flex mb-4 d-md-none">
                      <Moldura
                        link={"http://www.cafm.com.br/"}
                        mobile
                        white
                        image={CENTRO_AMERICA_FM}
                      />
                      <Moldura
                        link={"http://www.morenafm.com.br/"}
                        mobile
                        white
                        image={MORENA_FM}
                      />
                    </Row>

                    <Row className="d-flex d-md-none">
                      {/* <Moldura mobile image={LOGO} /> */}
                      <Moldura
                        link={"https://primeirapagina.com.br/"}
                        mobile
                        zoom={0.6}
                        image={PRIMEIRA_PAGINA}
                      />

                      <Moldura
                        link={"https://g1.globo.com/mt/mato-grosso/"}
                        link2={"https://g1.globo.com/ms/mato-grosso-do-sul/"}
                        double
                        mobile
                        zoom={0.6}
                        image={G1MT}
                        image2={G1MS}
                      />
                    </Row>
                    <Content.FloatingBar end="true" />
                  </Col>
                </Row>
                <Row className="mb-5 mb-md-0">
                  <Col>&nbsp;</Col>
                </Row>
              </Container>
            </div>
          </Container>
        </Wrapper>
      </Background>
    </>
  );
}

function Moldura(props) {
  if (props.double && props.mobile) {
    return (
      <>
        <Col className="col-6">
          <Content.Bloco mobile zoom={props.zoom} white={props.white}>
            <Row>
              <Col
                style={{ cursor: "pointer" }}
                /* onClick={_ => {
                  if (props.link) window.open(props.link, "_blank");
                }} */
                className="col-6 d-flex align-items-center justify-content-center"
              >
                <a href={props.link || '#'} target="_blank">
                <img src={props.image} />
              </a>
              </Col>
              <Col
                style={{ cursor: "pointer" }}
                /* onClick={_ => {
                  if (props.link2) window.open(props.link2, "_blank");
                }} */
                className="col-6 d-flex align-items-center justify-content-center"
              >
              <a href={props.link2 || '#'} target="_blank">
                <img src={props.image2} />
              </a>
              </Col>
            </Row>
          </Content.Bloco>
        </Col>
      </>
    );
  }

  if (props.double) {
    return (
      <>
        <Col className="col-4">
          <Content.Bloco double zoom={props.zoom} white={props.white}>
            <Row>
              <Col
                style={{ cursor: "pointer" }}
                /* onClick={_ => {
                  if (props.link) window.open(props.link, "_blank");
                }} */
                className="col-6 d-flex align-items-center justify-content-center"
              >
                <a href={props.link || '#'} target="_blank">
                  <img src={props.image} />
                </a>
              </Col>
              <Col
                style={{ cursor: "pointer" }}
                /* onClick={_ => {
                  if (props.link2) window.open(props.link2, "_blank");
                }} */
                className="col-6 d-flex align-items-center justify-content-center"
              >
              <a href={props.link2 || '#'} target="_blank">
                <img src={props.image2} />
              </a>
              </Col>
            </Row>
          </Content.Bloco>
        </Col>
      </>
    );
  }

  if (props.mobile) {
    return (
      <Col className="col-6">
        <Content.Bloco
        zoom={props.zoom}
        white={props.white}
        mobile
        >
        <Row>
          <Col
            style={{ cursor: "pointer" }}
            /* onClick={_ => {
              if (props.link) window.open(props.link, "_blank");
            }} */
            className="d-flex align-items-center justify-content-center"
          >
          <a href={props.link || '#'} target="_blank">
            <img src={props.image} />
          </a>
          </Col>
        </Row>
        </Content.Bloco>
      </Col>
    );
  }
  if (!props.link) {
    return (
      <>
      <Col className="col-4">
        <Content.Bloco
        zoom={props.zoom}
        white={props.white}
        >
        <Row>
          <Col
            className="d-flex align-items-center justify-content-center"
          >
          <img src={props.image} />
          </Col>
        </Row>
        </Content.Bloco>
      </Col>
    </>
    );
  }

  return (
    <>
      <Col className="col-4">
        <Content.Bloco
        zoom={props.zoom}
        white={props.white}
        >
        <Row>

          <Col
            style={{ cursor: "pointer" }}
            /* onClick={_ => {
              if (props.link) window.open(props.link, "_blank");
            }} */
            className="d-flex align-items-center justify-content-center"
          >
          <a href={props.link || '#'} target="_blank">
            <img src={props.image} />
          </a>
          </Col>

        </Row>
        </Content.Bloco>
      </Col>
    </>
  );
}
