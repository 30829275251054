import React from "react";
import { Wrapper, Content } from "./style";

import config from "config.json";

// import logo from "assets/img/logo_white.png";
import bg from "assets/img/bg.jpg";
import Diretor from "assets/img/diretoria/diretor.jpg";
import Fundador from "assets/img/diretoria/fundador.jpg";
import Presidente from "assets/img/diretoria/presidente.jpg";

//Components
import Background from "components/Background";

//3rd party
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import Menu from "components/Menu";
import { useState } from "react";
import { useEffect } from "react";
import anime from "animejs";

export default function (props) {
  const [localState, setLocalState] = useState({ isOpen: false });

  useEffect(() => {
    const tgt = document.getElementsByClassName("_menu_ref")[0];

    if (!tgt) {
      return;
    }

    const toggleMenu = () => {
      // //console.log("clicou");
      setLocalState({ ...localState, isOpen: !localState.isOpen });
    };

    tgt.addEventListener("click", toggleMenu);

    return () => {
      tgt.removeEventListener("click", toggleMenu);
    };
  }, [localState.isOpen]);

  useEffect(() => {
    anime
      .timeline({
        easing: `easeInOutQuad`
        // delay: (el, i) => i * 100
      })
      .add({
        targets: [".rowUp.first"],
        translateY: [50, 0],
        scale: [1.1, 1],
        opacity: [0, 1]
      })
      .add(
        {
          targets: [".rowUp:not(.first)"],
          translateY: [50, 0],
          scale: [1.1, 1],
          opacity: [0, 1]
        },
        `-=500`
      )
      .play();
  }, []);

  return (
    <>
      <Background image={bg}>
        <Wrapper>
          <Container fluid>
            <Row
              style={{
                top: 0,
                position: `fixed`,
                width: `100%`,
                zIndex: !!localState.isOpen ? 99999 : 10
              }}
            >
              <Menu></Menu>
            </Row>
            <div id="conteudo">
              <Row className="text-center mt-0 mt-md-5">
                <Col>
                  <Content.mainTitle>NOSSA HISTÓRIA</Content.mainTitle>
                  <Container>
                    <Row>
                      <Col className="col-12 col-md-6 offset-0 offset-md-3">
                        <Content.subtitle>
                          Um grupo genuinamente regional, apaixonado por
                          comunicação.
                        </Content.subtitle>
                      </Col>
                    </Row>
                    <Row className="text-justify">
                      <Col className="col-12 col-md-6 offset-0 offset-md-3">
                        <Content.paragraph>
                          Com quase seis décadas de atuação no coração do
                          Brasil, somos comunicação, emoção e interação.
                        </Content.paragraph>
                        <Content.paragraph>
                          Priorizamos credibilidade, levando informação e
                          entretenimento com responsabilidade e compromisso com
                          a qualidade.
                        </Content.paragraph>
                        <Content.paragraph>
                          Somos a Rede Matogrossense de Comunicação, companhia
                          que trilha o caminho da excelência desde sua fundação,
                          em 1965. Nesta caminhada, manter a solidez em meio à
                          turbulência faz do empresariado brasileiro um segmento
                          vencedor e temos a satisfação de fazer parte do time
                          de empreendedores perseverantes que acredita no país e
                          trabalha por seu desenvolvimento.
                        </Content.paragraph>
                      </Col>
                    </Row>
                  </Container>
                  <Row className="rowUp">
                    <Col>
                      <Content.diretoria>
                        <Container>
                          <Row>
                            <Col className="col-12 offset-0 col-lg-6 offset-lg-3">
                              <Row>
                                <Col className="col-12 col-lg-4 mb-5 mb-lg-0">
                                  <Moldura
                                    adjust={`60% 4%`}
                                    name="Ueze Elias Zahran"
                                    description="Fundador do Grupo Zahran"
                                    obs="In Memorian"
                                    image={Fundador}
                                  />
                                </Col>
                                <Col className="col-12 col-lg-4 mb-5 mb-lg-0">
                                  <Moldura
                                    adjust={`42% 4%`}
                                    name="Caio Turqueto"
                                    description="Presidente do Grupo Zahran"
                                    image={Presidente}
                                  />
                                </Col>
                                <Col className="col-12 col-lg-4 mb-5 mb-lg-0">
                                  <Moldura
                                    adjust={`65% 4%`}
                                    name="Nicomedes Silva Filho"
                                    description="Diretor Geral RMC"
                                    image={Diretor}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Container>
                      </Content.diretoria>
                    </Col>
                  </Row>
                  <Container>
                    <Row className="text-justify" style={{ marginTop: 20 }}>
                      <Col className="col-12 col-md-6 offset-0 offset-md-3">
                        <Content.paragraph>
                          Com trabalho desenvolvido graças à competência e
                          dedicação de nossos {config.dados.colaboradores}{" "}
                          colaboradores. Mais do que o cumprimento de metas,
                          nossa atuação retrata nosso compromisso maior:{" "}
                          <span>
                            atuar em prol do desenvolvimento de nossa região.
                          </span>
                        </Content.paragraph>
                        <Content.paragraph>
                          Desde nossa fundação, testemunhamos e noticiamos o
                          acontecer da história de nosso país e de nossa região.
                          Política, economia, comunidade, a divisão do Estado, o
                          desbravar da agricultura, o <i>boom</i> das{" "}
                          <i>commodities</i>, a construção de estradas, o
                          caminhar do desenvolvimento. Mato Grosso e Mato Grosso
                          do Sul registrados e apresentados em imagem e som.
                        </Content.paragraph>
                        <Content.paragraph>
                          {" "}
                          Por meio do pilar de nosso negócio, a comunicação,
                          conseguimos crescer e consolidar nossa presença e
                          aceitação. Com isso, passamos a atuar não somente nas
                          capitais, mas também no interior. Porque crescer está
                          no nosso DNA. Crescer de forma sustentável,
                          responsável e bem alicerçada.
                        </Content.paragraph>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
              <Row>
                <Col className="col-10 offset-1">
                  <h1> </h1>
                </Col>
              </Row>
            </div>
          </Container>
        </Wrapper>
      </Background>
    </>
  );
}

function Moldura(props) {
  return (
    <Row className="text-center">
      <Col className="col-12">
        <Content.FotoRedonda adjust={props.adjust} url={props.image} />
      </Col>
      <Col className="col-12 d-flex justify-content-center flex-column">
        <Content.NomeDiretoria>{props.name}</Content.NomeDiretoria>
        <Content.CargoDiretoria>
          {props.description}
          {(props.obs) ? (
            <div>{props.obs}</div>
          ) : ''}
        </Content.CargoDiretoria>
      </Col>
    </Row>
  );
}
