import styled from "styled-components";

export const Wrapper = styled.div`
  background: #fff;
  color: #222;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  font-family: "Futura";
  font-size: 2em;

  & .brand {
    margin-bottom: 20px;
    animation: pulse 0.5s alternate infinite ease-in-out;
  }

  @keyframes pulse {
    from {
      transform: scale(0.95);
      opacity: 0.9;
    }
    to {
      opacity: 1;
    }
  }
`;

export const WrapperOfWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-content: center;
  justify-content: center;
  position: absolute;
  top: 0;
  z-index: 9999999;
  transform: translateX(100vw);
`;
