import { createStore, applyMiddleware } from 'redux';
import createSagasMiddleware from 'redux-saga';

import reducers from 'redux/reducers';
import sagas from 'redux/sagas';

const sagaMiddleware = createSagasMiddleware();

export const store = createStore(reducers, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(sagas)