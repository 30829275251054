import React, { useEffect, useState } from "react";
import { Menu } from "./style";
import logo from "assets/img/logo.png";

import { useHistory } from "react-router-dom";
import { useWindowSize } from "react-use";

import { useSelector, useDispatch } from "react-redux";
/* import nextCookie from 'next-cookies';
import cookie from 'js-cookie'; */
import {Animated} from 'react-animated-css'

import Burguer from "components/Burguer/Burguer";

import menu from "./menu.json";

export default function(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  
  /* const [cookies, setCookies] = useState(nextCookie(''));
  const [lgpd, setLgpd] = useState('true');
  useEffect(() => {
    setLgpd((cookies.cookies_de_acordo)?cookies.cookies_de_acordo:'');
  }, [cookies]);

  function handleLGPD() {
      cookie.set('cookies_de_acordo', 'true', { expires: 1 });
      toggleAnimation();
  }
  
  const [anima, setAnima] = useState({animacao: true});
  
  const toggleAnimation = () => {
  
      let animacao = !anima.animacao;
      setAnima({animacao})
      setTimeout(()=>{setLgpd('true')},1000);
   } */

    return (
    <Menu>
      <div
        id="brand"
        onClick={() => {
          // history.push("/");
          dispatch({
            type: "PAGE.CHANGE",
            payload: { href: "/" },
            history
          });
        }}
      >
        <img src={logo} width={120}></img>
      </div>
      <ResponsiveMenu></ResponsiveMenu>
      {/* {lgpd!="true" && 

      <Animated
            animationIn="bounceInUp"
            animationOut="bounceOutDown"
            animationInDuration={400}
            animationOutDuration={400}
            isVisible={anima.animacao}
            style={{position: 'fixed', zIndex:'999',
                left: '5%',
                right: '5%',
                bottom: '16px'}}
        >
          <div id="cookie-banner-lgpd" className="cookie-banner-lgpd-visible cookie-banner-lgpd-animated" style={{display:'flex'}}>
              <div className="cookie-banner-lgpd-container">
                  <div className="cookie-banner-lgpd_text-box">
                      <span className="cookie-banner-lgpd_text">
                      Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência em nossos serviços, personalizar publicidade e recomendar conteúdo de seu interesse. Ao utilizar nossos serviços, você concorda com tal monitoramento.
                      Consulte a nossa política de privacidade <a href="http://privacidade.redematogrossense.com/politica-privacidade" target="_blank">clicando aqui</a>.
                      </span>
                  </div>
                  <div className="cookie-banner-lgpd_button-box">
                      <button className="cookie-banner-lgpd_accept-button" onClick={handleLGPD} style={{backgroundColor:'#0069DE'}}>PROSSEGUIR</button>
                  </div>
              </div>
          </div>
        </Animated>
      } */}

    </Menu>
  );
}

const ResponsiveMenu = props => {
  const hist = useHistory();
  const { width: wwidth, height: wheight } = useWindowSize();
  const dispatch = useDispatch();

  return (
    <>
      {wwidth >= 1024 ? (
        <>
          <ul id="menu_items">
            {menu.map((item, i) => {
              return (
                <li
                  key={i}
                  className={item.classes}
                  onClick={() => {
                    // hist.push(item.href);
                    dispatch({
                      type: "PAGE.CHANGE",
                      payload: { href: item.href, external: item.external },
                      history: hist
                    });
                  }}
                >
                  {item.title}
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <>
          <Burguer />
        </>
      )}


        

    </>
  );
};
