import anime from "animejs";
import dados from "pages/Cobertura/dados_cobertura.json";

let spacement = -100;

export const next = state => {
  anime({
    targets: [".myCarousel-item"],
    easing: `easeInOutBack`,
    translateY: (el, i) => {
      // if (i > state.indice + 1)
      //   return state.borderWidth >= spacement ? state.borderWidth : spacement;
      // if (i === state.indice + 1)
      //   return state.borderWidth >= spacement
      //     ? (state.borderWidth / 10) * 8
      //     : spacement;
      // if (i < state.indice - 1)
      //   return -(state.borderWidth >= spacement
      //     ? state.borderWidth
      //     : spacement);
      // if (i === state.indice - 1)
      //   return -(state.borderWidth >= spacement
      //     ? (state.borderWidth / 10) * 8
      //     : spacement);
      if (state.indice == i - 1) return -40;
      if (state.indice == i + 1) return 40;
      if (state.indice == i) return 0;
    },
    opacity: (el, i) => {
      if (dados[i].Estado !== state.bloco.Estado) return 0;
      if (i > state.indice + 1) return 0;
      if (i === state.indice + 1) return 0.5;
      if (i < state.indice - 1) return 0;
      if (i === state.indice - 1) return 0.5;
      if (state.indice == i) return 1;
    },
    scale: (el, i) => {
      if (dados[i].Estado !== state.bloco.Estado) return 0;
      if (i > state.indice + 1) return 0;
      if (i === state.indice + 1) return 0.75;
      if (i < state.indice - 1) return 0;
      if (i === state.indice - 1) return 0.75;
      if (state.indice == i) return 1.5;
    },
    // rotateY: (el, i) => {
    //   if (i > state.indice + 1) return 90;
    //   if (i === state.indice + 1) return 70;
    //   if (i < state.indice - 1) return -90;
    //   if (i === state.indice - 1) return -70;
    //   if (state.indice == i) return 0;
    // },
    zIndex: (el, i) => {
      if (i === state.indice) return 100;
      if (i !== state.indice) return 110;
    }
  });

  anime({
    targets: `#myCarousel-wrapper`,
    width: state.borderWidth + 100
  });

  anime({
    targets: `.myCarousel-legend`,
    ease: `easeInOutQuad`,
    opacity: [0, 1],
    translateY: [-20, 0]
  });

  anime({
    targets: `#dynamic-redpoint`,
    opacity: [1, 1],
    translateY: state.coordY,
    translateX: state.coordX
  });
};
