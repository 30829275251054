import React, { useState, useEffect, useRef } from "react";
import { Wrapper } from "./style";
import config from "../../config.json";
import anime from "animejs";
import Content from "./Content";

//import { useEffectOnce } from "react-use";

function Rodape(props) {
  const firstTime = useRef(true);
  const animation = useRef(null);
  const [status, setStatus] = useState({ open: false });

  const [year, setYear] = useState(new Date().getFullYear());

  const open = () => {
    setStatus({ ...status, open: !status.open });
  };

  const flipX = () => {
    if (animation.current) {
      animation.current.pause();
    }
    const _close = () => {
      animation.current = anime({
        targets: `.toggle`,
        rotateX: { value: `180deg`, duration: 5000 },
        translateY: { value: "-5px", duration: 5000 },
        padding: `20px`,
        background: `#111`,
        borderRadius: `100%`,
        elasticity: 0,
        duration: 2000
      });

      anime({
        targets: `.openn`,
        height: [`75px`, `50px`],
        easing: `easeInOutQuad`,
        duration: 250,
        elasticity: 0
      });
    };

    const _open = () => {
      if (animation.current) {
        animation.current.pause();
      }
      animation.current = anime({
        targets: `.toggle`,
        rotateX: { value: `0deg`, duration: 5000 },
        duration: 2000,
        translateY: [`0px`, `-40px`],
        padding: `40px`,
        background: `#090909`,
        elasticity: 0
      });

      anime({
        targets: `.openn`,
        height: `50vh`,
        easing: `easeInOutQuad`,
        duration: 250,
        elasticity: 0
      });
    };

    if (firstTime.current) {
      firstTime.current = false;
      _close();
      return;
    }

    if (status.open) {
      _open();
    } else {
      _close();
    }
  };

  useEffect(() => {
    flipX();
  }, [status.open]);

  return (
    <Wrapper id="footer" className={`openn`}>
      {/* <div className={`toggle`} onClick={open}><span>&#11167;</span></div> */}
      {!status.open ? (
        <span className="rodape">
          {config.app.name}. Todos os direitos reservados. &copy; {year}.
        </span>
      ) : (
        <Content></Content>
      )}
    </Wrapper>
  );
}

export default Rodape;
