import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import config from "../../config.json";
import anime from "animejs";

import { useWindowSize } from "react-use";

import { useSelector } from "react-redux";

import bg from "assets/img/bg.jpg";
import bg2 from "assets/img/bg2.jpg";

const pattern = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAEElEQVQImWNgYGD4z0AZAABABAEAuUSdjgAAAABJRU5ErkJggg==`;

const Background_s = styled.div`
  background: ${props => (props.image ? `url(${props.image})` : "#222")};
  background-size: cover;
  background-position: center 45%;
  color: #fff;
  height: 100vh;
  width: 100%;
  padding: 20px;
  position: absolute;

  &::before {
    background: url(${pattern}) repeat, rgba(0, 0, 0, 0.45);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: 0;
    transition: transform 1s ease-in-out;
  }
`;

export const Background = props => {
  const animation = useRef(null);
  const [animating, turnAnimation] = useState(false);

  const enterZoom = () => {
    if (animating) {
      animation.current.pause();
    }
    turnAnimation(true);
    animation.current = anime({
      targets: ".zoomedBackground",
      scale: { value: 1.075, duration: 3000 },
      elasticity: 0,
      easing: `easeInOutQuad`,
      complete: () => {
        turnAnimation(false);
      }
    });
  };

  const exitZoom = () => {
    if (animating) {
      animation.current.pause();
    }
    turnAnimation(true);
    animation.current = anime({
      targets: ".zoomedBackground",
      scale: 1,
      duration: 1500,
      elasticity: 0,
      easing: `easeInOutQuad`,
      complete: () => {
        turnAnimation(false);
      }
    });
  };

  //Background mudando
  const projeto = useSelector(state => state.project);

  const handleBg = () => {
    // //console.log("Entrou no handleBg");
    let retorno;
    switch (projeto.bg) {
      case "1":
        retorno = bg;
        break;
      case "2":
        retorno = bg2;
        break;
      default:
        retorno = bg;
        break;
    }

    return retorno;
  };

  return (
    <div
      onMouseEnter={enterZoom}
      onMouseLeave={exitZoom}
      style={{ overflow: "hidden", width: `100%`, height: `100vh` }}
    >
      <Background_s className={`zoomedBackground`} image={handleBg()} />
      <div>{props.children}</div>
    </div>
  );
};

export const Menu = styled.div`
  & {
    flex: 1;
    padding: 0.75cm 1cm;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & #brand {
    color: #fff;
    font-size: 2em;
  }

  & #menu_items {
    list-style: none;
    display: flex;

    & li {
      padding: 10px;
      // background: rgba(255,255,255,0.05);
      // box-shadow: 0px 2px 2px rgba(0,0,0,0.10);
      color: #fff;
      top: 0;
      transition: top 0.25s ease-in-out;
      cursor: pointer;
      font-family: "Futura";
      text-transform: uppercase;
      font-size: 0.8em;
      letter-spacing: 1px;

      &::selection {
        display: none;
      }

      &:not(:last-child) {
        margin-right: 30px;
      }

      &:hover {
        // background: rgba(255,255,255,0.1);
      }

      &.hire {
        background: ${config.theme.primary};
        color: ${config.theme.primary_text};
      }
    }
  }
`;

export const Conteudo = props => {
  useEffect(() => {
    anime({
      targets: [".description"],
      translateY: [20, 0],
      opacity: [0, 1],
      easing: `easeInOutQuad`
    });
  }, []);

  return <_Conteudo>{props.children}</_Conteudo>;
};

const _Conteudo = styled.div`
  & h1.mainTitle {
    font-family: "Gotham Title";
    color: #fff;
    line-height: 100%;
    font-size: 3.5em;

    @media (min-width: 992px) {
      font-size: 4em;
    }
  }

  & h1.mainTitle ~ p {
    font-family: "Gotham title";
    color: #fff;
    letter-spacing: 2px;
  }

  & div.description {
    background: #0006;
    color: #fff;
    font-family: "Futura";
    font-weight: 600;
    font-size: 1.5em;

    &::after {
      position: absolute;
      top: -10px;
      right: 10%;
      background: ${config.theme.primary};
      width: 10%;
      min-width: 100px;
      height: 20px;
      content: "";
    }

    & ul {
      list-style: none;
      font-size: 0.75em;
    }

    @media (min-width: 992px) {
      font-size: 2em;
    }
  }
`;
