import styled from "styled-components";
import { Container } from "react-bootstrap";
import { Content as _Content } from "pages/About/style";
import config from "config.json";

const pattern = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAEElEQVQImWNgYGD4z0AZAABABAEAuUSdjgAAAABJRU5ErkJggg==`;

export const Wrapper = styled.div`
  font-family: "Futura";
  text-shadow: 0px 2px 3px #0005;
  height: 100%;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0;
  }

  scrollbar-width: none;

  & #conteudo {
    margin-top: 100px;
    @media (min-width: 768px) {
      margin-top: 150px;
    }
    &::-webkit-scrollbar {
      width: 0;
    }

    scrollbar-width: none;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 150px);
    z-index: 2;
    /* padding-top: 150px; */
  }

  & .row.content {
    padding: 20px 25px;
    color: #fff;
    font-size: 0.5em;

    @media (min-width: 640px) {
      padding: 20px 15vw;
      font-size: 1em;
    }
  }

  & .col.title {
    h1 {
      display: inline-block;
      padding-bottom: 0px;
      margin-bottom: 40px;
      font-size: 4em;
      border-bottom: 4px solid #fff;
    }
  }

  & .background-dark-transparent {
    background: #0005;

    &:hover {
      background: #0009;
    }
  }
  & .paddingToMobile{
    padding-top: 0px;
    margin-top: -30px;
    @media (max-width: 770px) {
      padding-top:60px;
    }
  }
`;

export const Cobertura = styled.div`
  & h2 {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  & .bloco {
    /* background: red; */
    font-size: 12pt;
    display: flex;
    flex-flow: column;
    padding: 20px 0;

    & p {
      margin: 0;
      padding: 0;
    }

    & p span {
      font-weight: bold;
    }
  }
`;

export const Content = _Content;

Content.Legend = styled.div.attrs({ className: "myCarousel-legend" })`
  top: 50px;
  opacity: 0;
`;

Content.h1 = styled(_Content.mainTitle)`
  color: ${props => props.color || `#FFF`};
  line-height: 100%;
  font-family: "Gotham Title";
  font-size: 1.5rem !important;
  @media (max-width: 575px) {
    font-size: 1rem;
  }
  
`;

Content.FloatingBar = styled.div.attrs(props => ({
  className: `animado ${props.end ? "end" : ""}`
}))`
  width: 250px;
  height: 15px;
  background: ${config.theme.primary};
  position: absolute;
  z-index: 100;

  &:not(.end) {
    top: -8px;
    right: 8px;
  }

  &.end {
    bottom: -8px;
    left: 8px;
  }
`;

Content.h3 = styled.h3`
  color: #fff;
  font-size: 1rem !important;

`;
Content.cidadeTitle = styled.div`
  font-family: "Gotham text";
  color: #fff;
`;
Content.lista = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

Content.p = styled.div`
  color: #fff;
  & b {
    font-family: "Gotham";
  }
`;

Content.title2 = styled(Content.mainTitle)`
  font-size: 1.5em !important;
`;

Content.Titulo = styled.span`
  font-family: "Gotham Title";
  text-align: ${props => (props.left ? "left" : props.right ? "right" : "")};
`;

export const Dynamic = {};

Dynamic.Wrapper = styled.div`
  text-align: center;
`;

Dynamic.Map = styled.div`
  margin: 0 auto;
  display: block;
`;

Dynamic.Carousel = styled.div.attrs({ id: "myCarousel-wrapper" })`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  margin-top: 50px;
  @media (min-width: 768px) {
    margin-top: 60px;
  }

  width: 0;
  border: 3px solid #fff;
  border-left: none;
  border-right: none;
  color: #fff;
  font-family: "Gotham Text";
  text-transform: uppercase;
`;

Dynamic.CarouselItem = styled.div.attrs({ className: `myCarousel-item` })`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  position: absolute;
  opacity: 0;
  color: #fff;
  padding: 0 20px;

  &:hover {
    font-weight: bolder;
  }
`;

Dynamic.RedPoint = styled.div.attrs({ id: "dynamic-redpoint" })`
  width: 10px;
  height: 10px;
  background: red;
  position: absolute;
  top: calc(50% + 5px);
  z-index: 99999;
  border-radius: 100%;
  left: 0;
  opacity: 0;
  right: 0;
  margin: 0 auto;
`;

Dynamic._botao = styled.div`
  width: 50px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: absolute;
  border: 3px solid #fff;
  background: transparent;
  cursor: pointer;

  ${props => {
    if (props.active) {
      return `{background: #fff3}`;
    }
  }}

  &:hover {
    background: #fff1;
  }
`;
Dynamic.GoToMS = styled(Dynamic._botao)`
  top: 0;
  transform: translateX(120px) translateY(-65px);
  @media (min-width: 768px) {
    transform: translateX(250px);
  }
`;
Dynamic.GoToMT = styled(Dynamic._botao)`
  transform: translateX(-120px) translateY(-65px);
  @media (min-width: 768px) {
    transform: translateX(-250px);
  }
`;
