import React from "react";
import { Wrapper, Cobertura, Content, Dynamic } from "./style";

import config from "config.json";
import dados from "./dados_cobertura.json";
import * as animations from "./animation";

// import logo from "assets/img/logo_white.png";
import bg from "assets/img/bg.jpg";

//Components
import Background from "components/Background";
import Legenda from "./components/Legend";

//3rd party
import "bootstrap/dist/css/bootstrap.min.css";
import anime from "animejs";
import { Container, Row, Col } from "react-bootstrap";
import Menu from "components/Menu";
import { useState } from "react";
import { useEffect, useLayoutEffect } from "react";
import _ from "lodash";
import FA from "react-fontawesome";

import MapaMT from "assets/img/MT.png";
import MapaMS from "assets/img/MS.png";
import cidadesAnalogicas from "./cidadesAnalogicas.json";

export default function(props) {
  const [localState, setLocalState] = useState({ isOpen: false });

  useEffect(() => {
    const tgt = document.getElementsByClassName("_menu_ref")[0];

    if (!tgt) {
      return;
    }

    const toggleMenu = () => {
      // //console.log("clicou");
      setLocalState({ ...localState, isOpen: !localState.isOpen });
    };

    tgt.addEventListener("click", toggleMenu);

    return () => {
      tgt.removeEventListener("click", toggleMenu);
    };
  }, [localState.isOpen]);

  const [state, setState] = useState({
    indice: localStorage.getItem("background") == "1" ? 0 : 8,
    borderWidth: 100,
    bloco: dados[localStorage.getItem("background") == "1" ? 0 : 8],
    estado: MapaMT,
    _estado: dados[localStorage.getItem("background") == "1" ? 0 : 8].Estado,
    coordX: dados[localStorage.getItem("background") == "1" ? 0 : 8].Coords[0],
    coordY: dados[localStorage.getItem("background") == "1" ? 0 : 8].Coords[1],
  });

  useEffect(() => {
    if (
      state.bloco.Emissora.match("Centro América") &&
      state.estado !== MapaMT
    ) {
      setState({ ...state, estado: MapaMT, _estado: state.Estado });
    }
    if (state.bloco.Emissora.match("Morena") && state.estado !== MapaMS) {
      setState({ ...state, estado: MapaMS, _estado: state.Estado });
    }
    animations.next(state);
  }, [state]);

  let handleClick = (indice, element, bloco) => {
    // //console.log(bloco);
    //Seta indice para o clique:
    setState({
      ...state,
      indice,
      bloco,
      coordX: bloco.Coords[0] || 0,
      coordY: bloco.Coords[1] || 0,
      borderWidth: element.clientWidth,
    });
  };

  return (
    <>
      <Background image={bg}>
        <Wrapper>
          <Container fluid className="paddingToMobile">
            <Row
              style={{
                top: 0,
                position: `fixed`,
                width: `100%`,
                zIndex: !!localState.isOpen ? 99999 : 10,
              }}
            >
              <Menu></Menu>
            </Row>
            <div id="conteudo">
              {/* <Container>
                <Row className="text-center">
                  <Col>
                    <Content.h1>
                      DESLIGAMENTO DO SINAL DE TV ANALÓGICA
                    </Content.h1>
                  </Col>
                </Row>
                <Row className="text-center">
                  <Col>
                    <Content.h1>DATA FINAL: 31 DE JULHO DE 2022</Content.h1>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col style={{ position: `relative` }}>
                    <Content.FloatingBar />
                    <Row className="p-5 background-dark-transparent">
                      <Col className="mb-5">
                        <Content.h3 className="mb-4 text-left">
                          CIDADES CONTEMPLADAS EM MT | TV CENTRO AMÉRICA
                        </Content.h3>
                        <Content.lista>
                          {cidadesAnalogicas[0] &&
                            _.chunk(cidadesAnalogicas[0].cidades, 3).map(
                              (chunk) => {
                                return (
                                  <>
                                    {chunk.map((cidade, cidadeIndex) => {
                                      return (
                                        <div key={cidadeIndex}>
                                          <Content.cidadeTitle>
                                            {cidade.nome}
                                          </Content.cidadeTitle>
                                        </div>
                                      );
                                    })}
                                  </>
                                );
                              }
                            )}
                        </Content.lista>
                      </Col>

                      <Col className="mb-5">
                        <Content.h3 className="mb-4 text-left">
                          CIDADES CONTEMPLADAS EM MS | TV MORENA
                        </Content.h3>
                        <Content.lista>
                          {cidadesAnalogicas[1] &&
                            _.chunk(cidadesAnalogicas[1].cidades, 3).map(
                              (chunk) => {
                                return (
                                  <>
                                    {chunk.map((cidade, cidadeIndex) => {
                                      return (
                                        <div key={cidadeIndex}>
                                          <Content.cidadeTitle>
                                            {cidade.nome}
                                          </Content.cidadeTitle>
                                        </div>
                                      );
                                    })}
                                  </>
                                );
                              }
                            )}
                        </Content.lista>
                      </Col>
                    </Row>

                    <Content.FloatingBar end="true" />
                  </Col>
                </Row>
                <Row className="mb-5 mb-md-0">
                  <Col>&nbsp;</Col>
                </Row>
              </Container>

              <hr /> */}

              <Row className="text-center">
                <Col className="col-12 offset-0 col-md-8 offset-md-2">
                  <Content.mainTitle>COBERTURA</Content.mainTitle>
                  <Dynamic.Wrapper>
                    <Dynamic.Map>
                      <Dynamic.RedPoint />
                      <img src={state.estado} />
                    </Dynamic.Map>

                    <Dynamic.Carousel>
                      <FA
                        name="caret-up"
                        style={{ transform: `translateY(-60px)` }}
                      />
                      <FA
                        name="caret-down"
                        style={{
                          transform: `translateX(-9px) translateY(60px)`,
                        }}
                      />

                      {state.bloco.Estado !== "MT" && (
                        <Dynamic.GoToMT
                          onClick={() => handleClick(0, {}, dados[0])}
                          active={state._estado === "MT"}
                        >
                          MT
                        </Dynamic.GoToMT>
                      )}
                      {dados.map((bloco, blocoIndice) => {
                        return (
                          <Dynamic.CarouselItem
                            onClick={(e) =>
                              handleClick(blocoIndice, e.target, bloco)
                            }
                            key={blocoIndice}
                          >
                            {bloco.Cidade}
                          </Dynamic.CarouselItem>
                        );
                      })}
                      {state.bloco.Estado !== "MS" && (
                        <Dynamic.GoToMS
                          onClick={() => handleClick(8, {}, dados[8])}
                          active={state._estado === "MS"}
                        >
                          MS
                        </Dynamic.GoToMS>
                      )}
                    </Dynamic.Carousel>
                    <Row>
                      <Col
                        className={`pl-md-5 pr-md-5 mt-5 col-12 col-md-6 offset-md-3`}
                      >
                        <Row>
                          <Informacoes state={state} />
                        </Row>
                      </Col>
                    </Row>
                  </Dynamic.Wrapper>
                </Col>
              </Row>
            </div>
          </Container>
        </Wrapper>
      </Background>
    </>
  );
}

function Informacoes(props) {
  const { state } = props;

  useEffect(() => {
    anime({
      targets: ".boxSS",
      scale: [1.25, 1],
      opacity: [0, 1],
      rotateZ: [5, 0],
      easing: `easeInOutQuad`,
    });
  }, []);

  return (
    <>
      <Col className="boxSS">
        <Row className="p-3">
          {state.bloco.isTV && (
            <Col>
              <Legenda state={state} />
            </Col>
          )}
          <div className="w-100 mb-3 d-md-none" />
          {state.bloco.isRadio && (
            <Col>
              <Legenda variant="radio" state={state} />
            </Col>
          )}
        </Row>
      </Col>
    </>
  );
}
