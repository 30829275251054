import React from "react";
import { Wrapper, WrapperOfWrapper } from "./style";

import logo from "assets/img/logo.png";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useRef } from "react";
import anime from "animejs";

import config from "config.json";

export default () => {
  const isLoading = useSelector(state => state.project.isLoading);
  const animation = useRef();
  const first = useRef(true);

  const an = (reverse = false) => {
    const dl = 20;

    const a = anime({
      targets: [".loading_screen"],
      translateX: [`${first.current ? `-100vw` : `100vw`}`, `-100vw`],
      duration: config.dados.tempo,
      delay: first.current ? 1000 : 0,
      easing: `easeInOutQuad`
    });

    if (first.current) {
      first.current = false;
      // return;
    }

    return a;
  };

  useEffect(() => {
    //True, call in
    if (isLoading) {
      animation.current && animation.current.pause();
      animation.current = an(false);
    }
  }, [isLoading]);

  return (
    <>
      <WrapperOfWrapper className="loading_screen">
        <Wrapper style={{ width: `75vw`, zIndex: 11 }}></Wrapper>
        <Wrapper
          style={{
            background: config.theme.primary,
            zIndex: 10
          }}
        ></Wrapper>
      </WrapperOfWrapper>
    </>
  );
};
