import styled from "styled-components";
import { Container } from "react-bootstrap";
import { Content as ReferenceContent } from "pages/About/style";
import config from "config.json";

const pattern = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAEElEQVQImWNgYGD4z0AZAABABAEAuUSdjgAAAABJRU5ErkJggg==`;

export const Wrapper = styled.div`
  font-family: "Futura";
  text-shadow: 0px 2px 3px #0005;
  height: 100%;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0;
  }

  scrollbar-width: none;

  & #conteudo {
    margin-top: 150px;
    &::-webkit-scrollbar {
      width: 0;
    }

    scrollbar-width: none;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 150px);
    z-index: 2;
    /* padding-top: 150px; */
  }
`;

export const Content = {};

Content.h1 = styled(ReferenceContent.mainTitle)`
  color: ${props => props.color || `#FFF`};
  line-height: 100%;
  font-family: "Gotham Title";
  @media (max-width: 575px) {
    font-size: 2.5em;
  }
`;

Content.p = styled(ReferenceContent.paragraph)``;

Content.FloatingBar = styled.div.attrs(props => ({
  className: `animado ${props.end ? "end" : ""}`
}))`
  width: 250px;
  height: 15px;
  background: ${config.theme.primary};
  position: absolute;
  z-index: 100;

  &:not(.end) {
    top: -8px;
    right: 8px;
  }

  &.end {
    bottom: -8px;
    left: 8px;
  }
`;

Content.Bloco = styled.div.attrs({ className: "bloco" })`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0005;
  &:hover {
    background: #0002;
  }
  width: 100%;
  height: ${props => (props.mobile ? "100px" : "200px")};
  opacity: 0;
  transform: scale(0);

  & > img {
    height: 80%;
  }
`;
