import React, { useState, useRef/* , useEffect */ } from "react";
import { /* Animated, */ Button, Dropdown, Item } from "./style";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faBars } from "@fortawesome/free-solid-svg-icons";
import Loader from "./menu.json";

import Lottie from "react-lottie";

import { Motion, spring } from "react-motion";

import menu from "components/Menu/menu.json";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

export default function Burguer(props) {
  const hist = useHistory();

  const [state, setState] = useState({ isOpen: false });
  const lottieAnim = useRef(null);

  const handleAnimation = () => {
    setState({ ...state, isOpen: !state.isOpen });

    switch (state.isOpen) {
      case true:
        // lottieAnim.current.anim.setDirection(-1);
        lottieAnim.current.anim.playSegments([85, 85 * 2], 1);
        return;
      case false:
      default:
        lottieAnim.current.anim.playSegments([30, 85], 1);
        return;
    }
  };

  const opt = {
    loop: false,
    autoplay: false,
    animationData: Loader,
    rendererSettings: {
      className: "btn"
    }
  };

  const closeMenu = () => {
    lottieAnim.current.anim.goToAndPlay(85);
  };

  const dispatch = useDispatch();

  return (
    <>
      <Button className="_menu_ref" onClick={handleAnimation}>
        <Lottie ref={lottieAnim} options={opt}></Lottie>
      </Button>

      <Motion
        defaultStyle={{ opacity: 0, top: -50 }}
        style={{ opacity: spring(state.isOpen), top: spring(0) }}
      >
        {value => (
          <Dropdown
            style={{
              opacity: value.opacity,
              zIndex: state.isOpen ? 99999 : -1
            }}
          >
            {menu.map((item, i) => {
              return (
                <Item
                  key={i}
                  onClick={() => {
                    // hist.push(item.href);
                    dispatch({
                      type: "PAGE.CHANGE",
                      payload: { href: item.href, external: item.external },
                      history: hist
                    });
                  }}
                >
                  {item.title}
                </Item>
              );
            })}
            {/* <Item>Home</Item>
                        <Item>Quem somos</Item>
                        <Item>Produtos</Item>
                        <Item>Contato</Item>
                        <Item>Trabalhe conosco</Item> */}
          </Dropdown>
        )}
      </Motion>
    </>
  );
}
