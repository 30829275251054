//import React from "react";
import styled from "styled-components";

export const Button = styled.div`
  & .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1;
    z-index: 30;
    max-width: 50px;
    fill: #222 !important;
    &:hover {
      background: rgba(255, 255, 255, 0.15);
    }
  }
`;

export const Dropdown = styled.div`
  padding: 10px;
  background: #111;
  color: #fff;
  font-size: 0.85em;
  top: 112.5px;
  right: 50px;
  position: absolute;
  z-index: 20;
`;
export const Item = styled.div`
  padding: 10px;
  font-family: "Futura";
  text-transform: uppercase;
  background: #222;
  z-index: 20;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;
