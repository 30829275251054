import React, { useState } from 'react';
import './Content.scss';
import { useWindowSize, useEffectOnce } from 'react-use';
import anime from 'animejs';

import projetos from 'assets/projetos.json';
import { useEffect } from 'react';



function Content(props) {



    return (
        <>
            <div className="content">
                <h1>Nossos projetos:</h1>
                <div className="projetos">
                    <div className="projetos_inner">
                        {/* {projetos.map((projeto, i) => <Projeto key={i} props={projeto} />)} */}
                    </div>
                </div>
            </div>
        </>
    )
}



function Projeto({ props: projeto }) {
    const { width } = useWindowSize();
    const [size, setSize] = useState(0);

    useEffect(() => {
        setSize((width - 80) / 5)
    }, [width])

    return (
        <div className="projeto" style={{ width: size, background: `url(${projeto.imageUrl})` }}>{projeto.nome}</div>
    )
}

export default Content;