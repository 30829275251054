import styled from "styled-components";
import { Container } from "react-bootstrap";
import { Content as _Content } from "pages/About/style";

import config from "config.json";

const pattern = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAEElEQVQImWNgYGD4z0AZAABABAEAuUSdjgAAAABJRU5ErkJggg==`;

export const Wrapper = styled.div`
  font-family: "Futura";
  text-shadow: 0px 2px 3px #0005;
  height: 100%;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0;
  }

  scrollbar-width: none;

  & #conteudo {
    margin-top: 150px;
    &::-webkit-scrollbar {
      width: 0;
    }

    & h1.pageTitle {
      border-bottom: 3px solid #fff;
      font-size: 4em;
      margin-bottom: 20px;
    }

    scrollbar-width: none;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 150px);
    z-index: 2;
    /* padding-top: 150px; */
  }

  & .row.content {
    padding: 20px 25px;
    color: #fff;
    font-size: 0.5em;

    @media (min-width: 640px) {
      padding: 20px 15vw;
      font-size: 1em;
    }
  }

  & .form {
    min-height: 100vh;
    @media (min-width: 768px) {
      min-height: 50vh;
    }
    background: #fff;
    box-shadow: 0px 2px 3px #2222;
    color: #333;
    text-shadow: none;

    & p {
      margin: 0;
    }

    & .map {
      background-position: center;
      background-size: cover;
      padding: 0;
      @media (max-width: 768px) {
        height: 50vh;
      }
    }

    & .dados {
      padding: 40px;
      display: flex;
      flex-flow: column;
      justify-content: center;

      @media (max-width: 400px) {
        padding: 20px;
      }

      & p {
        font-size: 10pt;
        margin-bottom: 5px;
        &.title {
          font-weight: bolder;
          text-transform: uppercase;
          &:not(:first-child) {
            margin-top: 30px;
            @media (max-width: 400px) {
              margin-top: 10px;
            }
          }

          &.principal {
            color: ${config.theme.primary};
            font-family: "Gotham";
            font-size: 2em;
          }
        }
      }
    }
  }

  & .telefones {
    margin-top: 50px;

    & > .col > h3 {
      margin-bottom: 20px;
    }
    & > .col > p {
      margin: 10px;
      font-size: 11pt;

      &:last-child {
        margin-bottom: 30px;
      }
    }
  }
`;

export const Content = _Content;
