import { all, takeLatest, put, delay } from "redux-saga/effects";
import { useHistory } from "react-router-dom";
import config from "config.json";

function* changepage(action) {
  if (action.payload.external) {
    window.open(action.payload.external, "_blank");
    return;
  }
  yield put({ type: "PROJECT.LOADING.ON" });
  yield delay(config.dados.tempo / 2);
  action.history.push(action.payload.href);
  yield put({ type: "PROJECT.LOADING.OFF" });
}

export default function* mySaga() {
  yield takeLatest("PAGE.CHANGE", changepage);

  yield all([]);
}
