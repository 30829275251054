import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0px;
  min-height: 100vh;
  max-width: 100vw;
  background: #222;
`;

export const Conteudo = styled.div`
  height: 100%;
  min-height: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  & > h1 {
    color: #fff;
    margin-bottom: 20px;
  }

  & > p {
    background: #fff;
    color: darkred;
    padding: 10px 10px;
    border-radius: 3px;
  }
`;
